import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { TableCustom } from "../../components/Table/Table";
import GeneralApi from "../../services/GeneralApi";
import SearchInput from "../../components/searchInput/searchInput";
import ToastCustom from "../../components/Toast/ToastCustom";
import { toast } from "react-toastify";
import dayjs from "dayjs"; // Para formatear fechas

// Definir el formato de fecha
const formatDate = (dateString) => {
  return dayjs(dateString).format('DD/MM/YYYY HH:mm') + ' hrs';
};

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(amount);
};

const headers = [
  { key: 'clientName', label: 'Cliente', align: 'center' },
  { key: 'formattedPaymentDate', label: 'Fecha de pago', align: 'center' },
  { key: 'totalPayment', label: 'Pago total', align: 'center' },
  { key: 'authorizedBy', label: 'Autorizado por', align: 'center' }
];

const HistoricPayments = () => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1)

  const fetchPayments = async () => {
    setLoading(true);
    try {
      const response = await GeneralApi.put('payments/findAll', {
        page: 1,
        elementsByPage: 80000
      });

      const payments = response?.data?.entity?.payments ?? [];
      
      // Mapear los pagos para agregar el nombre completo del cliente, formatear la fecha y el autorizado
      const formattedPayments = payments.map(payment => ({
        ...payment,
        clientName: payment.client 
          ? `${payment.client.name} ${payment.client.patern} ${payment.client.matern}`.replace(/\s+/g, ' ').trim() 
          : "Usuario no encontrado",
        formattedPaymentDate: formatDate(payment.paymentDate),
        totalPayment: formatCurrency(payment.totalPayment),
        authorizedBy: payment.authorizedBy 
          ? `${payment.authorizedBy.name} ${payment.authorizedBy.patern} ${payment.authorizedBy.matern}`.trim() 
          : "Autorizado sin usuario"
      }));

      // Ordenar los pagos por fecha, de más reciente a más antiguo
      const sortedPayments = formattedPayments.sort((a, b) => {
        return new Date(b.paymentDate) - new Date(a.paymentDate);
      });

      setRows(sortedPayments);
      setFilteredRows(sortedPayments);
      setLoading(false); 
    } catch (error) {
      setError(error);
      setLoading(false); 
    }
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  useEffect(() => {
    const filtered = rows.filter(row => {
      // Convertir todos los valores que deseas filtrar a cadenas en minúsculas para comparar
      const clientNameMatch = row.clientName && row.clientName.toLowerCase().includes(searchTerm.toLowerCase());
      const paymentDateMatch = row.formattedPaymentDate && row.formattedPaymentDate.toLowerCase().includes(searchTerm.toLowerCase());
      const totalPaymentMatch = row.totalPayment && row.totalPayment.toLowerCase().includes(searchTerm.toLowerCase());
      const authorizedByMatch = row.authorizedBy && row.authorizedBy.toLowerCase().includes(searchTerm.toLowerCase());
  
      // Retornar true si al menos una de las condiciones es verdadera
      return clientNameMatch || paymentDateMatch || totalPaymentMatch || authorizedByMatch;
    });
    setPage(1)
    setFilteredRows(filtered);
  }, [searchTerm, rows]);
  

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="main-content">
      <ToastCustom />
      <h3>Histórico de pagos</h3>
      <hr className="divider" />
      <div className="d-flex justify-content-between mb-3">
        <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="danger" />
        </div>
      ) : (
        <TableCustom headers={headers} rows={filteredRows} page={page} setPage={setPage}/>
      )}
    </div>
  );
};

export default HistoricPayments;
