import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import GeneralApi from "../../services/GeneralApi";
import { toast } from "react-toastify";

const ModalPayments = ({ show, onHide, type, onUpdate, selectedItem }) => {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      // Solo actualizar formData cuando el modal esté visible
      if (selectedItem) {
        setFormData(selectedItem);
      } else {
        setFormData({});
      }
    }
  }, [show, selectedItem]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const endpoints = {
        descuentos: selectedItem
          ? `discounts/update/${selectedItem.id}`
          : "discounts/create",
        precios: selectedItem
          ? `prices/update/${selectedItem.id}`
          : "prices/create",
        "tipos-pago": selectedItem
          ? `payment-types/update/${selectedItem.id}`
          : "payment-types/create",
      };
      const endpoint = endpoints[type];
      let response;

      // Validación para el endpoint de descuentos
      if (type === "descuentos") {
        const amountDiscount = Number(formData.amountDiscount);
        if (isNaN(amountDiscount) || amountDiscount > 300) {
          toast.warning("El descuento no puede ser mayor a 300");
          setLoading(false);
          return;
        }
        formData.amountDiscount = amountDiscount;
      }

      // Validación para el endpoint de precios
      if (type === "precios") {
        const days = Number(formData.days)
        const quantity = Number(formData.quantity);
  
        formData.quantity = quantity;
        formData.days = days
      }

      if (endpoint) {
        response = selectedItem
          ? await GeneralApi.put(endpoint, formData)
          : await GeneralApi.post(endpoint, formData);
        if (response.status === (selectedItem ? 200 : 201)) {
          toast.success(
            `¡${
              type === "tipos-pago"
                ? "Tipos de Pago"
                : type.charAt(0).toUpperCase() + type.slice(1)
            } ${selectedItem ? "actualizado" : "agregado"} exitosamente!`
          );
          onHide(); // Cierra el modal después de guardar
          onUpdate(); // Llama a la función para actualizar la tabla
        } else {
          if (Array.isArray(response.data.message)) {
            const errorMessage = response.data.message.join("\n");
            toast.error(errorMessage);
          } else {
            toast.error(
              response.data.message || "Error al procesar la solicitud"
            );
          }
        }
      } else {
        toast.error("Tipo de dato no válido");
      }
    } catch (error) {
      console.error("Error en el proceso:", error);
      toast.error("Error al agregar o actualizar el dato");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{`${selectedItem ? "Editar" : "Agregar"} ${
          type === "tipos-pago"
            ? "Tipos de Pago"
            : type.charAt(0).toUpperCase() + type.slice(1)
        }`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {type === "descuentos" && (
            <>
              <Form.Group controlId="formDiscountName">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre del descuento"
                  name="name"
                  value={formData.name || ""}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formDiscountAmount">
                <Form.Label>Descuento</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Monto del descuento"
                  name="amountDiscount"
                  value={formData.amountDiscount || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </>
          )}
          {type === "precios" && (
            <>
              <Form.Group controlId="formPriceName">
                <Form.Label>Nombre:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre del precio"
                  name="name"
                  value={formData.name || ""}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formPriceQuantity">
                <Form.Label>Precio:</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Cantidad"
                  name="quantity"
                  value={formData.quantity || ""}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formPricePeriodicity">
                <Form.Label>Periodicidad:</Form.Label>
                <Form.Select
                  name="days"
                  value={formData.days || ""}
                  onChange={handleChange}
                >
                  <option value="">Seleccionar periodicidad</option>
                  <option value="1">Día</option>
                  <option value="15">Quincenal</option>
                  <option value="30">Mensual</option>
                  <option value="60">Bimestral</option>
                  <option value="90">Trimestral</option>
                  <option value="180">Semestral</option>
                  <option value="365">Anual</option>
                </Form.Select>
              </Form.Group>
            </>
          )}
          {type === "tipos-pago" && (
            <Form.Group controlId="formPaymentTypeName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre del tipo de pago"
                name="name"
                value={formData.name || ""}
                onChange={handleChange}
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={handleSave} disabled={loading}>
          {loading ? "Guardando..." : "Guardar"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPayments;
