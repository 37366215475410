import React, { useState, useEffect } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import {
  FaMoneyBillWave,
  FaPercentage,
  FaTag,
  FaCalendarAlt
} from "react-icons/fa";
import { toast } from "react-toastify";
import GeneralApi from "../../services/GeneralApi";
import ModalCopyClientId from "../Clients/ModalCopyClientId";

const ModalPaymentClient = ({
  show,
  handleClose,
  client,
  onPaymentSuccess,
}) => {
  const [paymentTypeId, setPaymentType] = useState("");
  const [discountId, setDiscount] = useState("");
  const [priceId, setPrice] = useState("");
  const [months, setMonths] = useState("");
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [prices, setPrices] = useState([]);
  const [isFirstPayment, setIsFirstPayment] = useState(false); // Verifica si es el primer pago
  const [showClientIdModal, setShowClientIdModal] = useState(false); // Estado para mostrar el modal del ID
  const [clientId, setClientId] = useState(null); 
  useEffect(() => {
    if (show && client?.id) {
      setClientId(client.id);
      const fetchData = async () => {
        try {
          const [paymentTypesResponse, discountsResponse, pricesResponse] =
            await Promise.all([
              GeneralApi.get("/payment-types/findAll"),
              GeneralApi.get("/discounts/findAll"),
              GeneralApi.get("/prices/findAll"),
            ]);

          if (paymentTypesResponse.data.code === 201) {
            setPaymentTypes(paymentTypesResponse.data.entity);
          }
          if (discountsResponse.data.code === 201) {
            setDiscounts(discountsResponse.data.entity);
          }
          if (pricesResponse.data.code === 201) {
            setPrices(pricesResponse.data.entity);
          }

          // Extraer los pagos del cliente actual
          const payments = await GeneralApi.put("payments/findAll", {
            page: 1,
            elementsByPage: 10,
          });
          // Filtramos los pagos para obtener solo aquellos que tienen un cliente y que coinciden con el client.id
          const paymentsList = payments.data.entity.payments.filter(
            (payment) => payment.client && payment.client.id === client.id
          );

          console.log(
            `Historial de pagos del cliente ${client.id}:`,
            paymentsList
          );
          // Verificar si es el primer pago
          if (paymentsList.length === 0) {
            console.log(`Es el primer pago para el cliente ${client.id}`);
            setIsFirstPayment(true);
          } else {
            console.log(
              `El cliente ${client.id} ya ha registrado pagos anteriormente`
            );
            setIsFirstPayment(false);
          }
        } catch (error) {
          console.error("Error al obtener los datos:", error);
        }
      };

      fetchData();
    }
  }, [show, client]);

  const handleSave = async () => {
    if (!paymentTypeId || !priceId) {
      toast.warning("Por favor, seleccione un tipo de pago y un precio");
      return;
    }
    if (months > 12) {
      toast.warning("Los meses no deben ser mayor a 12");
      return;
    }

    const paymentData = {
      clientId: client.id,
      priceId: Number(priceId),
      months: Number(months),
      paymentTypeId: Number(paymentTypeId),
    };

    try {
      const response = await GeneralApi.post("/payments/create", paymentData);

      if (response.data.code === 201) {
        toast.success("Pago registrado exitosamente");
        onPaymentSuccess(); // Llama al callback para actualizar la tabla

        // Si es el primer pago, abre el modal para copiar el ID del cliente
        if (isFirstPayment) {
          setShowClientIdModal(true);
        }
      } else {
        toast.error(
          "Hubo un error al registrar el pago: " + response.data.message
        );
      }
    } catch (error) {
      console.error("Error al registrar el pago:", error);
      toast.error("Hubo un error al registrar el pago");
    }
  };

  const handleCloseClientIdModal = () => {
    setShowClientIdModal(false);
  };


  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Registrar pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formPaymentType">
              <Form.Label>Tipo de Pago</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FaMoneyBillWave />
                </InputGroup.Text>
                <Form.Control
                  as="select"
                  value={paymentTypeId}
                  onChange={(e) => setPaymentType(e.target.value)}
                  required
                >
                  <option value="">Seleccione un tipo de pago</option>
                  {paymentTypes.map((type, index) => (
                    <option key={index} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </Form.Control>
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="formDiscount">
              <Form.Label>Descuentos</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FaPercentage />
                </InputGroup.Text>
                <Form.Control
                  as="select"
                  value={discountId}
                  onChange={(e) => setDiscount(e.target.value)}
                >
                  <option value="">Seleccione el descuento</option>
                  {discounts.map((discount, index) => (
                    <option key={index} value={discount.id}>
                      {discount.name} | ${discount.amountDiscount}
                    </option>
                  ))}
                </Form.Control>
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="formPrice">
              <Form.Label>Precios</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FaTag />
                </InputGroup.Text>
                <Form.Control
                  as="select"
                  value={priceId}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                >
                  <option value="">Seleccione el precio</option>
                  {prices.map((price, index) => (
                    <option key={index} value={price.id}>
                      {price.name} | ${price.quantity}
                    </option>
                  ))}
                </Form.Control>
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="formMonths">
              <Form.Label>Plazo</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FaCalendarAlt />
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  value={months}
                  onChange={(e) => setMonths(e.target.value)}
                  placeholder="Ingrese el plazo"
                  required
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="danger" onClick={handleSave}>
            Pagar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para mostrar el ID del cliente si es su primer pago */}
      <ModalCopyClientId 
                show={showClientIdModal}
                handleClose={handleCloseClientIdModal}
                clientId={clientId}
            />
    </>
  );
};

export default ModalPaymentClient;
