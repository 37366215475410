import React, { useEffect, useState } from "react";
import { Row, Col, Button, Collapse } from "react-bootstrap";
import PaymentCard from "../../components/Cards/PaymentCard";
import { FaPercentage, FaMoneyBillWave, FaCreditCard, FaPlus } from "react-icons/fa";
import GeneralApi from "../../services/GeneralApi";
import { toast } from "react-toastify";
import { TableCustom } from "../../components/Table/Table";
import { getTableConfig } from "./TableConfig";
import ModalPayments from "./ModalPayments";
import ToastCustom from "../../components/Toast/ToastCustom";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

const Payments = () => {
  const [open, setOpen] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false); // Estado para mostrar el diálogo de confirmación
  const [page, setPage] = useState(1); 
 
  const fetchData = async (endpoint) => {
    setLoading(true);
    try {
      const response = await GeneralApi.get(endpoint);
      if (response.data) {
        setData(response.data.entity);
      } else {
        toast.error("Error al obtener datos");
      }
    } catch (error) {
      toast.error("Error al obtener datos");
    } finally {
      setLoading(false);
    }
  };

  const handleToggle = (contentType) => {
    if (open === contentType) {
      setOpen(null);
    } else {
      fetchData(getEndpoint(contentType));
      setOpen(contentType);
      setPage(1); // Resetea la página solo si cambias el tipo de contenido
    }
  };
  

  const getEndpoint = (contentType) => {
    if (contentType === "descuentos") return "discounts/findAll";
    if (contentType === "precios") return "prices/findAll";
    if (contentType === "tipos-pago") return "payment-types/findAll";
    return "";
  };
  const getEndpointDelete = (contentType) => {
    if (contentType === "descuentos") return "discounts/";
    if (contentType === "precios") return "prices/";
    if (contentType === "tipos-pago") return "payment-types/";
    return "";
  };
  const getEndpointUpdate = (contentType) => {
    if (contentType === "descuentos") return "discounts/";
    if (contentType === "precios") return "prices/";
    if (contentType === "tipos-pago") return "payment-types/";
    return "";
  };

  const handleUpdate = () => {
    fetchData(getEndpoint(open)); // Actualiza los datos de la tabla después de una acción
  };
  const handleEdit = (item) => {
    setSelectedItem(item);
    setModalType(open);
    setShowModal(true);
  };
  const handleDeleteConfirmation = (item) => {
    setSelectedItem(item);
    setShowConfirmDelete(true);
  };
  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const endpoint = getEndpointDelete(open);
      const response = await GeneralApi.delete(`${endpoint}${id}`);
      if (response.data.code === 201) {
        toast.success("Elemento eliminado exitosamente");
        handleUpdate(); // Actualiza la tabla después de eliminar
      } else {
        toast.error("Error al eliminar el elemento " + response.data.message);
      }
    } catch (error) {
      toast.error("Error al eliminar el elemento");
    } finally {
      setLoading(false);
    }
  };


  const renderTable = () => {
    if (loading) return <p>Cargando...</p>;
    if (!data || !data.length) return <p>No hay datos disponibles</p>;

    const { headers, rows } =  getTableConfig(open, data, handleEdit, handleDeleteConfirmation);
    return <TableCustom headers={headers} rows={rows} page={page} setPage={setPage} />;
  };
  
  return (
    <div className="main-content">
      <h3>Pagos</h3>
      <hr className="divider" />
      <Row className="g-4">
        <ToastCustom />
        <Col md={4}>
          <PaymentCard
            title="Descuentos"
            text="Aplica descuentos especiales a tus clientes"
            icon={FaPercentage}
            buttonText="Gestionar Descuentos"
            onButtonClick={() => handleToggle("descuentos")}
          />
        </Col>
        <Col md={4}>
          <PaymentCard
            title="Precios"
            text="Configura y ajusta los precios de tus servicios"
            icon={FaMoneyBillWave}
            buttonText="Gestionar Precios"
            onButtonClick={() => handleToggle("precios")}
          />
        </Col>
        <Col md={4}>
          <PaymentCard
            title="Tipos de Pago"
            text="Define los tipos de pago aceptados"
            icon={FaCreditCard}
            buttonText="Gestionar Tipos de Pago"
            onButtonClick={() => handleToggle("tipos-pago")}
          />
        </Col>
      </Row>

      {/* Sección de tablas colapsables */}
      <Collapse in={open === "descuentos"}>
        <div className="mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <h4>Descuentos</h4>
            <Button
              variant="danger"
              className="mt-3 d-flex align-items-center"
              onClick={() => {
                setModalType('descuentos');
                setShowModal(true);
              }}
            >
              <FaPlus className="mr-2" />
              Agregar descuento
            </Button>
          </div>
          {renderTable()}
        </div>
      </Collapse>

      <Collapse in={open === "precios"}>
        <div className="mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <h4>Precios</h4>
            <Button
              variant="danger"
              className="mt-3 d-flex align-items-center"
              onClick={() => {
                setModalType('precios');
                setShowModal(true);
              }}
            >
              <FaPlus className="mr-2" />
              Agregar Precio
            </Button>
          </div>
          {renderTable()}
        </div>
      </Collapse>

      <Collapse in={open === "tipos-pago"}>
        <div className="mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <h4>Tipos de Pago</h4>
            <Button
              variant="danger"
              className="mt-3 d-flex align-items-center"
              onClick={() => {
                setModalType('tipos-pago');
                setShowModal(true);
              }}
            >
              <FaPlus className="mr-2" />
              Agregar Tipo de Pago
            </Button>
          </div>
          {renderTable()}
        </div>
      </Collapse>

      {/* Modal genérico */}
      <ModalPayments
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setSelectedItem(null); // Limpia el item seleccionado al cerrar el modal
        }}
        type={modalType}
        onUpdate={handleUpdate}
        selectedItem={selectedItem} // Pasa el item seleccionado al modal
      />
       <ConfirmDeleteModal
        show={showConfirmDelete}
        onHide={() => setShowConfirmDelete(false)}
        onConfirm={handleDelete}
        item={selectedItem} // Pasa el ítem seleccionado al diálogo
      />
    </div>
  );
};

export default Payments;
