import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FaCopy, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import GeneralApi from "../../services/GeneralApi";

const ModalCopyClientId = ({ show, handleClose, clientId, edit }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false); // Estado para mostrar modal de confirmación
  const roles = JSON.parse(localStorage.getItem("roles")); // Obtener los roles desde localStorage

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(clientId)
      .then(() => {
        toast.success("ID copiado al portapapeles");
      })
      .catch((err) => {
        console.error("Error al copiar el ID: ", err);
        toast.error("Hubo un error al copiar el ID");
      });
  };

  const handleDeleteFingerprint = async () => {
    try {
      const response = await GeneralApi.delete(
        `fingerprint-templates/remove/${clientId}`
      );
      if (response.data.code === 201) {
        toast.success("Huellas eliminadas correctamente");
      } else {
        const errorMessage = Array.isArray(response.data.message)
          ? response.data.message.join("\n")
          : response.data.message || "Error al eliminar las huellas";
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Error al eliminar las huellas:", error);
      toast.error(
        "Error al eliminar las huellas. Por favor, inténtelo de nuevo."
      );
    } finally {
      setShowConfirmModal(false); // Cerrar el modal de confirmación después de la operación
    }
  };

  const handleReset = () => {
    clientId = "";
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleReset(); // Resetea al cerrar el modal
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {edit ? "Gestión de huellas" : "¡Cliente Nuevo!"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              Registra las huellas del usuario, copia el código para ingresarlo
              en el módulo central:
            </Form.Label>
            <Form.Control type="text" value={clientId} readOnly />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={copyToClipboard}>
            <FaCopy /> Copiar ID
          </Button>
          {edit && (roles.includes("manager") || roles.includes("superAdmin") || roles.includes("admin")) && (
            <Button
              variant="danger"
              onClick={() => setShowConfirmModal(true)} // Mostrar modal de confirmación
            >
              <FaTrash /> Eliminar huellas
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* Modal de confirmación */}
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Estás seguro de que deseas eliminar las huellas de este cliente? Esta
          acción no se puede deshacer.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleDeleteFingerprint}>
            Confirmar Eliminación
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalCopyClientId;
